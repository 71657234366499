import * as React from 'react';
import axios from 'axios';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';
import { AsyncStorageService } from '../services/AsyncStorage';
import $ from 'jquery';
import CertificateView from '../components/CertificateView';
import { ToastContainer, toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import { useMediaQuery } from 'react-responsive';
import {isMobile} from 'react-device-detect';

interface Props {

}
interface State {
    currentYear: any;
    stageNo: any;
    type: any;
    link: any;
    monthList:any;
    month:any;
}

class Mycertificate2024 extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {
            currentYear: '',
            stageNo: '',
            type: '',
            link: '',
            monthList:[],
            month:''
        }
    }
    componentDidMount = () => {
        const date = new Date();
        const currentYear = 2024;
        this.setState({ currentYear });
        const monthList = ["January", "February", "March", "April", "May",
        "June", "July", "August", "September", "October", "November", "December"];
        this.setState({monthList});
    }

    showModel = async (stageNo,type) => {
        this.setState({ stageNo,month:''});
        const user = await AsyncStorageService.getUser();
        const userId = user.user_id;
        const { currentYear } = this.state;
        let init: any = {
            method: 'POST',
            headers: {
                'content-type': 'multipart/form-data',
                'Accept': 'application/json'
            },
            mode: 'cors',
            cache: 'default',
            dataType: 'json',
            async: true,
        };
        this.setState({ link: '' });
        let formData = new FormData();
        formData.append('user_id', userId);
        formData.append('stage_no', stageNo);
        formData.append('type', type);
        formData.append('year', currentYear);
        await axios.post("https://api.cyruns.in/api/athletes.php?action=stagecertificate2024", formData, init)
            .then(response => {
                if (response.data.msgcode == 0) {
                    const link = response.data.download_link;
                    this.setState({ link });
                    const id = document.getElementById('showCertificateModel');
                    if (link != "" && !isMobile) {
                        $(id).click();
                    }else if(isMobile && link != ""){
                        // window.open(link,"_self");
                        // window.location.href = link;
                        let link_elem = document.createElement("a");
                        // Set the href attribute
                        link_elem.href = link;
                        // Set any other attributes or text if needed
                        link_elem.textContent = "Download Certificate"; // You can skip this line if you don't want to display text
                        // Append the link to the body of the page
                        document.body.appendChild(link_elem);
                        // Trigger a click event on the link
                        link_elem.click();


                    }
                } else {
                    toast.error(response.data.msg);
                }
            })
    }
    monthlyCertificate=async(month,monthName)=>{ 
        this.setState({month:monthName,stageNo:"",
        });
        const date = new Date();
        date.setFullYear(date.getFullYear());
        const currentMonth = date.getMonth()+1;
        console.log(month);
        console.log(currentMonth);

        //  if(month < currentMonth){
            const user = await AsyncStorageService.getUser();
            const userId = user.user_id;
            const { currentYear } = this.state;
            let init: any = {
                method: 'POST',
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json'
                },
                mode: 'cors',
                cache: 'default',
                dataType: 'json',
                async: true,
            };
            this.setState({ link: '' });
            let formData = new FormData();
            formData.append('user_id',userId);
            formData.append('month',month);
            formData.append('year',currentYear);
            await axios.post("https://api.cyruns.in/api/athletes.php?action=monthlyCertificate2024", formData, init)
                .then(response => {
                    if (response.data.msgcode == 0) {
                        const link = response.data.download_link;
                        this.setState({ link });
                        const id = document.getElementById('showCertificateModel');
                        if (link != "" && !isMobile) {
                            $(id).click();
                        }else if(isMobile && link != ""){
                            // window.open(link,"_self");
                            // window.location.href = link;
                            // $(id).click();
                            let link_elem = document.createElement("a");
                            // Set the href attribute
                            link_elem.href = link;
                            // Set any other attributes or text if needed
                            link_elem.textContent = "Download Certificate"; // You can skip this line if you don't want to display text
                            // Append the link to the body of the page
                            document.body.appendChild(link_elem);
                            // Trigger a click event on the link
                            link_elem.click();

                        }
                    } else {
                        toast.error(response.data.msg);
                    }
                })
        //  }else{
        //      toast.error('This month certificate is not available');
        //  }
      
    }
    render() {
        const { currentYear, link ,monthList} = this.state;
       
        return (
            <div className='certificate-main container'>
                <ToastContainer />
                <a href="#" className="btn stage_btn" id='showCertificateModel'
                    data-toggle="modal" data-target="#modal-fullscreen" style={{ display: 'none' }}></a>
                <div className="top_rank_inner">
                    <div className='rank_img resolution'>
                        <h3 className='cert_resolution_text'>Resolution {currentYear} Certificates</h3>
                        <img src="./Images/athlet_leaderboard.svg" />
                    </div>
                </div>
                <div className='certiblok_main'>
                   
                    <div className='container'>
                    <div className='stage_title'>
                        <p>Stage Certificates</p>
                    </div>
                        <div className='row'>
                            <div className="col-md-3 stage_container">
                                <a href="#" className="btn stage_btn stage_2024_certi stage1"
                                   // data-toggle="modal" data-target="#modal-fullscreen"
                                    onClick={() => this.showModel(1,'certi')}>
                                    <div className='stage_btn_cont'>
                                        <img src='./Images/stage/stage1_2024.jpg' />
                                        <div className='stage_btn_text'>
                                            <p>stage - 1</p>
                                            <p>up to 506 KM</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-3 stage_container">
                                <a href="#" className="btn stage_btn stage_2024_certi stage2"
                                    // data-toggle="modal" data-target="#modal-fullscreen"
                                    onClick={() => this.showModel(2,'certi')}>
                                    <div className='stage_btn_cont'>
                                        <img src='./Images/stage/stage2_2024.jpg' />
                                        <div className='stage_btn_text'>
                                            <p>stage - 2</p>
                                            <p>up to 1012 KM</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-3 stage_container">
                                <a href="#" className="btn stage_btn stage_2024_certi stage3"
                                    // data-toggle="modal" data-target="#modal-fullscreen"
                                    onClick={() => this.showModel(3,'certi')}>
                                    <div className='stage_btn_cont'>
                                        <img src='./Images/stage/stage3_2024.jpg' />
                                        <div className='stage_btn_text'>
                                            <p>stage - 3</p>
                                            <p>up to 1518 KM</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-3 stage_container">
                                <a href="#" className="btn stage_btn stage_2024_certi stage4"
                                    // data-toggle="modal" data-target="#modal-fullscreen"
                                    onClick={() => this.showModel(4,'certi')}>
                                    <div className='stage_btn_cont'>
                                        <img src='./Images/stage/stage4_2024.jpg' />
                                        <div className='stage_btn_text'>
                                            <p>stage - 4</p>
                                            <p>up to 2024 KM</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>

                      

                    </div>
                </div>


                <div className='certiblok_main'>
                    <div className='stage_title'>
                        <p>Monthly Certificates</p>
                    </div>
                    <div className='container'>
                        <div className='row'>

                            {/* <div className="comming-soon">
                                <span>Monthly Certificate Comming Soon</span>
                            </div> */}

                        {monthList.map((month, index) =>{ 
                        return  <div className='col-md-2 col stage_container' key={index}>
                                    <a href="#" className="btn stage_btn montly_btn monthly_btn_new"
                                        onClick={() =>this.monthlyCertificate(index + 1,month)} 
                                    >
                                        <div className='monthly_ico'>
                                            <img src={`./Images/2024/${month.toLowerCase()}.png`} />
                                        </div>
                                        <div className='monthly_name'>
                                            <p>{month}</p>
                                        </div>
                                    </a>
                                </div>
                            })}
                           
                        </div>
                    </div>
                </div>
        
               
            

                {/* {link != '' ? */}
                    <MediaQuery minDeviceWidth={1224} >
                        <div className="modal fade modal-fullscreen" id="modal-fullscreen" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered  fullscreenModel">
                                <CertificateView stageNo={this.state.stageNo} Link={link} Month={this.state.month}/>
                            </div>
                        </div>
                    </MediaQuery>
                {/* :
                null
                }
                    */}

            </div>
        )
    }

}

export default Mycertificate2024;